<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.refresh")}} {{$t("message.permis")}}</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            v-loading="loadingData"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item>
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >{{$t("message.parental_permission")}}</span
                  >
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    :inputValue="form.slug"
                    v-model="form.slug"
                    :disabled="true"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="parent_name">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                    >{{$t("message.translated_resolution")}}</span
                  >
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    :inputValue="form.name"
                    v-model="form.name"
                  ></crm-input>
                </el-form-item>
              </el-col>              
              <div
                v-for="(child, index) in form.has_children"
                :key="'permissions-' + index"
              >
                <el-col :span="12">
                  <el-form-item prop="parent_name">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                      >{{$t("message.children_permission")}}</span
                    >
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="child.slug"
                      v-model="child.slug"
                      :disabled="true"
                    ></crm-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="parent_name">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24"
                      > {{$t("message.children_translated_permission")}}</span
                    >
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="child.name"
                      v-model="child.name"
                    ></crm-input>
                  </el-form-item>
                </el-col>
              </div>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
  mixins: [drawer, show, form],
  name: "PermissionController",
  components: {},
  data() {
    return {
      form: {
        children: [],
      },
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters({
      rules: "permissions/rules",
      model: "permissions/model",
      columns: "permissions/columns",
      mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "permissions/update",
      show: "permissions/show",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
